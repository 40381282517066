import * as React from 'react'
import { map } from 'lodash'
import type { DetailSectionProps } from './DetailSection.types'

const DetailSection: React.FC<DetailSectionProps> = ({ title, data }) => {
    if (!title) return null
    return (
        <div className="pb-6">
            <div className="mb-4 mt-7">
                <h3 className="text-overline-md uppercase text-semantic-content-inkLight">
                    {title}
                </h3>
            </div>
            <div className="flex flex-col gap-[10px]">
                {map(data, (item, index) => {
                    if (!item || !item.description) return
                    return (
                        <div
                            className="flex items-center justify-between text-semantic-content-ink"
                            key={item.title + index}>
                            <h5 className="text-body-sm-r">{item.title}</h5>
                            <p className="text-body-md-b">{item.description}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export default DetailSection
