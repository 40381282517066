import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Avatar, PersonalItem } from '@components'
import type { PersonFieldProps } from './PersonField.types'
import { cn } from '@core/utils'

const PersonField: React.FC<PersonFieldProps> = ({
    data,
    error,
    helperText,
    onChange,
    PersonItemProps,
    ListFooterComponent,
    className,
    ...props
}) => {
    const { t } = useTranslation()

    const change = React.useRef(true)

    const onChangePerson = React.useCallback(() => {
        onChange(map(data, item => item.phone))
    }, [data, onChange])

    React.useEffect(
        () => {
            if (change.current) {
                change.current = false
                return
            }

            onChangePerson()
        }, // eslint-disable-next-line
        [data],
    )

    return (
        <div
            data-error={error}
            className={cn('flex w-full flex-col', className)}
            {...props}>
            {map(data, (item, index) => (
                <PersonalItem
                    className="duration-300 animate-in fade-in zoom-in-95 hover:cursor-default"
                    key={index}
                    avatarChildComponent={
                        item.full_name && (
                            <Avatar.Fallback className="transition-colors duration-300 ease-in-out group-hover:bg-semantic-background-inkInverse">
                                {item.full_name?.charAt(0)}
                            </Avatar.Fallback>
                        )
                    }
                    avatarIconProps={{
                        name: 'person',
                        size: 24,
                    }}
                    title={
                        item.full_name ||
                        t('@wallet.GiftCardMake.form_person_field_unknown_user')
                    }
                    description={item.phone}
                    divider
                    {...(typeof PersonItemProps === 'function'
                        ? PersonItemProps(item)
                        : PersonItemProps)}
                />
            ))}
            {ListFooterComponent && ListFooterComponent}
            {helperText && (
                <div
                    className={cn(
                        'text-left !text-label-md !font-normal',
                        error && 'text-semantic-content-error',
                    )}>
                    {helperText}
                </div>
            )}
        </div>
    )
}
export default PersonField
