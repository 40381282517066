import * as React from 'react'
import { Icon } from '@components'
import type { AvatarIconProps } from './Avatar.types'
import { cn } from '@core/utils'

const AvatarIcon: React.FC<AvatarIconProps> = ({ className, ...props }) => {
    return (
        <div
            className={cn(
                'flex h-full w-full items-center justify-center rounded-full bg-semantic-background-primary',
                className,
            )}>
            <Icon {...props} />
        </div>
    )
}
export default AvatarIcon
