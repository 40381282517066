import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Container, SegmentedControl } from '@components'
import walletApi from '@wallet/api'
import { GiftCardActivities, GiftCardActivitiesHeader } from '@wallet/views'
import GiftCardMake from './GiftCardMake'

const GiftCard: React.FC = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()

    const _WALLET_ID = localStorage.getItem('user.wallet_id') || 0

    const walletDetailsQuery = walletApi.useWalletDetailsQuery({
        wallet_id: _WALLET_ID as number,
    })

    const wallet = React.useMemo(
        () => walletDetailsQuery.data?.data,
        [walletDetailsQuery.data],
    )

    if (!wallet) return null

    return (
        <React.Fragment>
            <Container
                title={t('@wallet.GiftCard.route_header_title')}
                goBackProps={{
                    onClick: () =>
                        location.key !== 'default'
                            ? navigate(-1)
                            : navigate('/'),
                }}>
                <Container.Left
                    name={wallet.company.name}
                    currency={wallet.company.currency}
                    amount={wallet.balance}
                    point={wallet.point}>
                    <Container.Content className="lg:min-h-[720px]">
                        <SegmentedControl defaultValue="make">
                            <SegmentedControl.List className="mb-10">
                                <SegmentedControl.Trigger value="make">
                                    {t(
                                        '@wallet.GiftCardMake.route_header_title',
                                    )}
                                </SegmentedControl.Trigger>
                                <SegmentedControl.Trigger value="my_sent">
                                    {t(
                                        '@wallet.GiftCardMySent.route_header_title',
                                    )}
                                </SegmentedControl.Trigger>
                                <SegmentedControl.Trigger value="sent_to_me">
                                    {t(
                                        '@wallet.GiftCardSentToMeHistory.route_header_title',
                                    )}
                                </SegmentedControl.Trigger>
                            </SegmentedControl.List>

                            <SegmentedControl.Content
                                value="make"
                                className="duration-300 animate-in fade-in zoom-in-95">
                                <GiftCardMake wallet={wallet} />
                            </SegmentedControl.Content>

                            <SegmentedControl.Content value="my_sent">
                                <GiftCardActivitiesHeader
                                    title={t(
                                        '@wallet.GiftCardMySent.route_header_title',
                                    )}
                                    type="my_sent"
                                    companyId={wallet.company.id}
                                />

                                <GiftCardActivities
                                    type="my_sent"
                                    companyId={wallet.company.id}
                                    isExpired="0"
                                />
                            </SegmentedControl.Content>

                            <SegmentedControl.Content value="sent_to_me">
                                <GiftCardActivitiesHeader
                                    title={t(
                                        '@wallet.GiftCardSentToMe.route_header_title',
                                    )}
                                    type="sent_to_me"
                                    companyId={wallet.company.id}
                                />

                                <GiftCardActivities
                                    type="sent_to_me"
                                    companyId={wallet.company.id}
                                    isExpired="0"
                                />
                            </SegmentedControl.Content>
                        </SegmentedControl>
                    </Container.Content>
                </Container.Left>
                <Container.Right
                    title={t('@wallet.GiftCard.content_header_title')}
                    description={t(
                        '@wallet.GiftCardInfo.info_first_description',
                    )}
                />
            </Container>
        </React.Fragment>
    )
}
export default GiftCard
