import { find } from 'lodash'
import type { WalletResponseMeta } from '@wallet/api'

const findCompanyFromMeta = (companyId: number, meta: WalletResponseMeta) => {
    return find(meta.companies, { id: companyId })
}

export default {
    findCompanyFromMeta,
}
