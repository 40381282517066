import { createSlice } from '@reduxjs/toolkit'
import type { AppSettings, Company } from '@core/api'
import baseApi, { type User } from '@core/api'
import walletApi from '@wallet/api'
import type { BaseState } from './BaseSlice.types'

const initialState: BaseState = {
    platform: null,
    user: {} as User,
    pusherEnv: {
        host: null,
        key: null,
        port: null,
    },
    company: {} as Company,
    settings: {} as AppSettings,
    wallet_modules: [],
}

const BaseSlice = createSlice({
    name: 'baseSlice',
    initialState: initialState,
    reducers: {
        setPlatform: (state, action) => {
            state.platform = action.payload
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            baseApi.endpoints.me.matchFulfilled,
            (state, action) => {
                state.user = action.payload.data
            },
        )
        builder.addMatcher(
            baseApi.endpoints.appInfo.matchFulfilled,
            (state, action) => {
                state.pusherEnv = action.payload.data.pusher
            },
        )
        builder.addMatcher(
            baseApi.endpoints.initialize.matchFulfilled,
            (state, action) => {
                state.company = action.payload.data.company
                state.settings = action.payload.data.app_settings
            },
        )
        builder.addMatcher(
            walletApi.endpoints.walletDetails.matchFulfilled,
            (state, action) => {
                state.wallet_modules =
                    action.payload.meta.waas_modules_sort_list
            },
        )
    },
})

export const { setPlatform } = BaseSlice.actions

export default BaseSlice.reducer
