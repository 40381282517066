import * as React from 'react'
import { Spinner } from '@components'
import type { SplashProps } from './Splash.types'
import { useNavigateToPage } from '../Initialize/Initialize.hooks'

const Splash: React.FC<SplashProps> = ({ initialize, isNavigate }) => {
    const navigateToPage = useNavigateToPage()

    React.useEffect(() => {
        if (initialize && isNavigate) return navigateToPage(initialize)
    }, [initialize, isNavigate, navigateToPage])

    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center gap-6 duration-500 ease-in-out animate-in fade-in zoom-in-95">
            <Spinner size={28} />
        </div>
    )
}

export default Splash
