import * as React from 'react'
import InputMask, { type ReactInputMask } from 'react-input-mask'
import { Icon } from '@components'
import type { InputProps } from './Input.types'
import { useMaskTextInput } from './Input.hooks'
import { cn } from '@core/utils'
import './Input.css'

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    (
        {
            leftIconProps,
            leftIcon,
            rightIconProps,
            rightIcon,
            label,
            labelLeftIcon,
            labelRightIcon,
            helperText,
            error,
            mask,
            maskType,
            maskOptions,
            LayoutRightComponent,
            labelClassName,
            inputClassName,
            className,
            containerProps,
            InputContainerAs,
            onBlur,
            ...props
        },
        ref,
    ) => {
        const [focused, setFocused] = React.useState<boolean>(false)
        const InputContainerComp = InputContainerAs || 'label'

        const textInputMask = useMaskTextInput(
            maskType,
            props.value as string,
            maskOptions,
        )

        return (
            <div
                data-error={error}
                data-focused={focused}
                data-disabled={props.disabled}
                className={cn('form-input__container animate-enter', className)}
                {...containerProps}>
                {label && (
                    <label htmlFor={props.id} className="form-input__label">
                        {labelLeftIcon && (
                            <Icon name={labelLeftIcon} size={16} />
                        )}
                        <span className="text-label-md">{label}</span>
                        {labelRightIcon && (
                            <Icon name={labelRightIcon} size={16} />
                        )}
                    </label>
                )}
                <InputContainerComp
                    htmlFor={props.id}
                    className={cn(
                        'form-input__input-container',
                        labelClassName,
                    )}>
                    {leftIcon && (
                        <Icon name={leftIcon} size={24} {...leftIconProps} />
                    )}
                    {(textInputMask || mask) && (
                        <InputMask
                            ref={ref as React.Ref<ReactInputMask>}
                            className={cn(inputClassName)}
                            mask={textInputMask ?? mask}
                            {...props}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                        />
                    )}
                    {!mask && !textInputMask && (
                        <input
                            ref={ref}
                            type="text"
                            className={cn(inputClassName)}
                            {...props}
                            onFocus={() => setFocused(true)}
                            onBlur={e => {
                                setFocused(false)
                                onBlur && onBlur(e)
                            }}
                        />
                    )}
                    {!LayoutRightComponent && rightIcon && (
                        <Icon name={rightIcon} size={24} {...rightIconProps} />
                    )}
                    {LayoutRightComponent && LayoutRightComponent}
                </InputContainerComp>
                {helperText && (
                    <span className="form-input__helper">{helperText}</span>
                )}
            </div>
        )
    },
)

export default Input
