import { yup } from '@core/middlewares'
import i18n from '@core/translations'

export const giftCardMakeValidationSchema = yup.object().shape({
    wallet_id: yup.number().required(),
    balance: yup.number().required().min(1),
    expire_date: yup.date().required(),
    description: yup.string().required(),
    phones: yup
        .array()
        .required()
        .min(1, i18n.t('@wallet.GiftCardMake.validation_phones_min')),
})
