import * as React from 'react'
import { forwardRef } from 'react'
import CurrencyInput, {
    type CurrencyInputProps,
} from 'react-currency-input-field'
import { cn } from '@core/utils'
import './Input.css'

interface InputCurrencyProps extends CurrencyInputProps {
    label?: string
    error?: boolean
    helperText?: string
}

const InputCurrency: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<InputCurrencyProps> &
        React.RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, InputCurrencyProps>(
    ({ label, helperText, error, className, ...props }, ref) => {
        return (
            <div
                data-error={error}
                className="currency-input-container space-y-2">
                {label && (
                    <label className="form-input__label text-label-md">
                        {label}
                    </label>
                )}

                <CurrencyInput
                    ref={ref}
                    className={cn('currency-input', className)}
                    {...props}
                />
                {helperText && (
                    <span className="form-input__helper block">
                        {helperText}
                    </span>
                )}
            </div>
        )
    },
)

InputCurrency.displayName = 'InputCurrency'

export default InputCurrency
