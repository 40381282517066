import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button } from '@components'
import { useAppSelector } from '@core/store'
import { TimerUtils } from '@guest/utils'
import type { OnboardingLocationProps } from './Onboarding.types'
import { authSubmitByType } from './Onboarding.utils'
import OnboardingSwiper from './OnboardingSwiper'
import './Onboarding.css'

const Onboarding = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location: OnboardingLocationProps = useLocation()

    const company = useAppSelector(state => state.baseSlice.company)

    const [loading, setLoading] = React.useState<boolean>(false)

    const handleSubmitAuth = React.useCallback(
        async (type: 'login') => {
            setLoading(true)

            await authSubmitByType({
                phone: location.state?.auth_data?.phone?.raw,
            })
                .then(response => {
                    const duration = TimerUtils.calculateDuration(
                        response.data.expires_at,
                        response.data.server_time,
                    )

                    navigate('/otp-verify', {
                        state: {
                            auth_data: location.state?.auth_data,
                            actionType: type,
                            phone: location.state?.auth_data?.phone?.raw,
                            duration: duration,
                            code: response.data.code,
                            wallet_id: location.state?.wallet_id,
                            company_id: location.state?.company.id,
                        },
                    })
                })
                .catch(error => {
                    if (type === 'login' && error.status === 404) {
                        return console.error('User not found')
                    }
                })
                .finally(() => setLoading(false))
        },
        [
            location.state?.auth_data,
            location.state?.company.id,
            location.state?.wallet_id,
            navigate,
        ],
    )

    return (
        <div className="onboarding-swiper-container">
            <OnboardingSwiper color={company.color} />
            <Button
                className="w-full"
                children={t('@guest.Onboarding.login_button')}
                loading={loading}
                disabled={loading}
                onClick={() => handleSubmitAuth('login')}
            />
        </div>
    )
}

export default Onboarding
