import * as React from 'react'
import { Slottable } from '@radix-ui/react-slot'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { useTabObserver } from '@core/hooks'
import { cn } from '@core/utils'

import mergeRefs from 'merge-refs'

const SegmentedControlList = React.forwardRef<
    React.ComponentRef<typeof TabsPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
        floatingBgClassName?: string
    }
>(({ children, className, floatingBgClassName, ...rest }, forwardedRef) => {
    const [lineStyle, setLineStyle] = React.useState({ width: 0, left: 0 })

    const { mounted, listRef } = useTabObserver({
        onActiveTabChange: (_, activeTab) => {
            const { offsetWidth: width, offsetLeft: left } = activeTab
            setLineStyle({ width, left })
        },
    })

    return (
        <TabsPrimitive.List
            ref={mergeRefs(forwardedRef, listRef)}
            className={cn(
                'relative isolate grid h-[46px] w-full auto-cols-fr grid-flow-col items-center gap-1 rounded-[13px] bg-semantic-background-secondary px-[3px]',
                className,
            )}
            {...rest}>
            <Slottable>{children}</Slottable>

            <div
                className={cn(
                    'absolute inset-y-[3px] left-0 -z-10 rounded-[11px] bg-solid-brand-5 transition-transform duration-300',
                    {
                        hidden: !mounted,
                    },
                    floatingBgClassName,
                )}
                style={{
                    transform: `translate3d(${lineStyle.left}px, 0, 0)`,
                    width: `${lineStyle.width}px`,
                    transitionTimingFunction: 'cubic-bezier(0.65, 0, 0.35, 1)',
                }}
                aria-hidden="true"
            />
        </TabsPrimitive.List>
    )
})
SegmentedControlList.displayName = 'SegmentedControlList'

export default SegmentedControlList
