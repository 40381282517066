import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Row, Sheet } from '@components'
import { GiftCardHistory } from '@wallet/views'
import type { GiftCardActivitiesHeaderProps } from './GiftCardActivities.types'

const GiftCardActivitiesHeader: React.FC<GiftCardActivitiesHeaderProps> = ({
    title,
    type,
    companyId,
}) => {
    const { t } = useTranslation()
    const [isOpenSheet, setIsOpenSheet] = React.useState<boolean>(false)

    return (
        <React.Fragment>
            <Row className="mb-4 flex items-center justify-between duration-300 animate-in fade-in zoom-in-95">
                <Row.Title title={title} className="uppercase" />

                <Button
                    variant="secondary"
                    size="tiny"
                    children={t(
                        '@wallet.GiftCardHistoryTabs.route_header_title',
                    )}
                    rounded="pill"
                    onClick={() => setIsOpenSheet(true)}
                />
            </Row>

            <Sheet
                open={isOpenSheet}
                onOpenChange={open => setIsOpenSheet(open)}>
                <GiftCardHistory type={type} companyId={companyId} />
            </Sheet>
        </React.Fragment>
    )
}
export default GiftCardActivitiesHeader
