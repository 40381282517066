import * as React from 'react'
import { Button } from '@components'
import type { CampaignItemBoxProps } from './CampaignItem.types'
import { useFormatCurrency } from './CampaignItem.hooks'
import { useContentConfig } from '@core/hooks'
import { cn } from '@core/utils'

const CampaignItemBox: React.FC<CampaignItemBoxProps> = ({
    type,
    isUsed,
    point,
    percent,
    currency,
    description,
    className,
    actionButtonClick,
}) => {
    const content = useContentConfig()
    const { currencySymbol, formattedText } = useFormatCurrency(point, currency)

    const formattedPercent = React.useMemo(() => {
        return percent ? `${percent}%` : ''
    }, [percent])

    return (
        <div
            data-is-used={isUsed}
            className={cn('campaign-item-box animate-enter', className)}>
            <div className="campaign-item-card__content">
                {type === 'percentage' && (
                    <div className="campaign-item-card__content__point">
                        <span>{formattedPercent}</span>
                        <span>{content.campaignItem.pointSuffix}</span>
                    </div>
                )}
                {type === 'fixed' && (
                    <div className="campaign-item-card__content__point">
                        <span>{currencySymbol + formattedText}</span>
                        <span>{content.campaignItem.pointSuffix}</span>
                    </div>
                )}
                <p>{description}</p>
            </div>
            <Button
                className="w-full"
                children={
                    isUsed
                        ? content.campaignItem.disabledButtonText
                        : content.campaignItem.detailsButtonText
                }
                variant="primary"
                rounded="pill"
                size="tiny"
                onClick={actionButtonClick}
                disabled={isUsed}
            />
        </div>
    )
}
CampaignItemBox.displayName = 'CampaignItemBox'

export default CampaignItemBox
