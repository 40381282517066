import * as React from 'react'
import { PersonalItem } from '@components'
import type { GiftCardActivitiesLoaderProps } from './GiftCardActivities.types'

const GiftCardActivitiesLoader: React.FC<GiftCardActivitiesLoaderProps> = ({
    limit = 6,
}) => {
    return (
        <React.Fragment>
            {Array.from({ length: limit }).map((_, index) => (
                <PersonalItem.Loader key={index} />
            ))}
        </React.Fragment>
    )
}
export default GiftCardActivitiesLoader
