import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cn } from '@core/utils'

const SegmentedControlTrigger = React.forwardRef<
    React.ComponentRef<typeof TabsPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...rest }, forwardedRef) => {
    return (
        <TabsPrimitive.Trigger
            ref={forwardedRef}
            className={cn(
                'peer',
                'relative z-10 h-10 rounded-[11px] fill-semantic-content-inkMedium px-1 !text-body-sm-b text-semantic-content-inkMedium outline-none sm:!text-body-md-b',
                'flex items-center justify-center gap-1.5',
                'transition duration-300 ease-out',
                'focus:outline-none',
                'data-[state=active]:fill-white data-[state=active]:text-white',
                className,
            )}
            {...rest}
        />
    )
})
SegmentedControlTrigger.displayName = 'SegmentedControlTrigger'

export default SegmentedControlTrigger
