import * as React from 'react'
import { Avatar, Icon } from '@components'
import type {
    PersonalItemProps,
    PersonalItemStaticProperties,
} from './PersonalItem.types'
import { cn, useTheme } from '@core/utils'
import PersonalItemLoader from './PersonalItemLoader'
import PersonItemDetail from './PersonItemDetail'

const PersonalItem: React.FC<PersonalItemProps> &
    PersonalItemStaticProperties = ({
    as,
    type = 'default',
    title,
    description,
    amount,
    currency,
    datetime,
    divider,
    avatarProps,
    avatarChildComponent,
    avatarIconProps,
    LayoutRightComponent,
    className,
    ...props
}) => {
    const theme = useTheme().colors
    const Comp = as || 'div'

    return (
        <Comp
            className={cn(
                'group flex h-[72px] w-full items-center gap-3 rounded-[13px] transition-colors duration-300 hover:cursor-pointer lg:px-4 lg:hover:bg-semantic-background-primary',
                className,
            )}
            {...props}>
            <Avatar className="size-12 shrink-0" {...avatarProps}>
                {avatarChildComponent && avatarChildComponent}
                {!avatarChildComponent && (
                    <Avatar.Icon
                        name="person-plus"
                        size={24}
                        color={theme.semantic.content.inkMedium}
                        className="transition-colors duration-300 ease-in-out group-hover:bg-semantic-background-inkInverse"
                        {...avatarIconProps}
                    />
                )}
            </Avatar>
            <div
                className={cn(
                    'flex flex-grow items-center justify-between py-5',
                    divider &&
                        'border-b border-semantic-background-tertiary transition-colors duration-300 group-hover:border-transparent',
                )}>
                <div className="flex flex-col gap-[2px] text-left">
                    <span className="text-body-md-b text-semantic-content-ink">
                        {title}
                    </span>
                    {description && (
                        <p className="text-body-sm-r text-semantic-content-inkLight">
                            {description}
                        </p>
                    )}
                </div>

                {type !== 'history' &&
                    LayoutRightComponent &&
                    LayoutRightComponent}
                {type !== 'history' && !LayoutRightComponent && (
                    <Icon
                        name="caret-right"
                        size={24}
                        color={theme.semantic.content.inkLight}
                    />
                )}
                {type === 'history' && (
                    <PersonItemDetail
                        amount={amount}
                        currency={currency}
                        date={datetime}
                    />
                )}
            </div>
        </Comp>
    )
}

PersonalItem.displayName = 'PersonalItem'

PersonalItem.Loader = PersonalItemLoader

export default PersonalItem
