import walletApi, { type GiftCardMySentItem } from '@wallet/api'
import type { GiftCardDetailType } from '@wallet/views'

export const getActivitiesQuery = (type: GiftCardDetailType) => {
    switch (type) {
        case 'my_sent':
            return walletApi.useGiftCardMySentQuery
        case 'sent_to_me':
            return walletApi.useGiftCardSentToMeQuery
        case 'my_sent_history':
            return walletApi.useGiftCardMySentHistoryQuery
        case 'sent_to_me_history':
            return walletApi.useGiftCardSentToMeHistoryQuery
        default:
            return walletApi.useGiftCardMySentQuery
    }
}

export const getPersonalItemDescription = (
    type: GiftCardDetailType,
    item: GiftCardMySentItem,
) => {
    switch (type) {
        case 'my_sent':
            return item.receiver?.full_name ?? item.receiver_phone
        case 'sent_to_me':
            return item.sender.full_name
        case 'my_sent_history':
            return item.receiver?.full_name ?? item.receiver_phone
        case 'sent_to_me_history':
            return item.sender.full_name
    }
}
