import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Input, PersonalItem, Row, Sheet } from '@components'
import walletApi from '@wallet/api'
import type { GiftCardAddUserProps } from './GiftCard.types'

const GiftCardAddUser: React.FC<GiftCardAddUserProps> = ({
    persons,
    setIsOpenChange,
    setPersons,
}) => {
    const { t } = useTranslation()

    const [phone, setPhone] = React.useState<string>('')

    const [findUserApply, findUserResult] =
        walletApi.useGiftCardFindUserMutation()

    const handleAddPerson = React.useCallback(async () => {
        const phones = map(persons, contact => contact.phone)

        const result = await findUserApply({
            phones: [...phones, phone],
        }).unwrap()

        if (result.data.length === 0) return

        setPersons(result.data)
        setIsOpenChange(false)
        setPhone('')
    }, [persons, findUserApply, phone, setPersons, setIsOpenChange])

    return (
        <Sheet.Content loading={findUserResult.isLoading}>
            <Sheet.Header>
                <Sheet.Title>
                    {t('@wallet.GiftCardMake.form_person_field_footer')}
                </Sheet.Title>
                <Sheet.Description>
                    {t('@wallet.GiftCardMake.add_person_description')}
                </Sheet.Description>
            </Sheet.Header>
            <div>
                <Input
                    leftIcon="search"
                    className="mb-12"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                />
                {phone.length > 0 && (
                    <Row className="space-y-4 duration-300 animate-in fade-in zoom-in-95">
                        <Row.Title
                            title={t('@wallet.GiftCardMake.directory')}
                            className="uppercase"
                        />

                        <PersonalItem
                            title={phone}
                            description={t(
                                '@wallet.GiftCardMake.click_to_add_person',
                            )}
                            divider
                            onClick={handleAddPerson}
                        />
                    </Row>
                )}
            </div>
        </Sheet.Content>
    )
}
export default GiftCardAddUser
