import type * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import type { SegmentedControlStaticProperties } from './SegmentedControl.types'
import SegmentedControlContent from './SegmentedControlContent'
import SegmentedControlList from './SegmentedControlList'
import SegmentedControlTrigger from './SegmentedControlTrigger'

const SegmentedControl: React.FC<
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
> &
    SegmentedControlStaticProperties = props => {
    return <TabsPrimitive.Root {...props} />
}

SegmentedControl.displayName = 'SegmentedControlRoot'

SegmentedControl.List = SegmentedControlList
SegmentedControl.Trigger = SegmentedControlTrigger
SegmentedControl.Content = SegmentedControlContent

export default SegmentedControl
