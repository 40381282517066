import * as React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { map } from 'lodash'
import { Avatar, EmptySheet, PersonalItem, Sheet } from '@components'
import { GiftCardUtils } from '@wallet/utils'
import { GiftCardDetail } from '@wallet/views'
import type { GiftCardActivitiesProps } from './GiftCardActivities.types'
import { usePagination } from '@core/hooks'
import {
    getActivitiesQuery,
    getPersonalItemDescription,
} from './GiftCardActivities.utils'
import { cn } from '@core/utils'
import GiftCardActivitiesLoader from './GiftCardActivitiesLoader'

const GiftCardActivities: React.FC<GiftCardActivitiesProps> = ({
    type,
    companyId,
    isExpired,
    isLoader = true,
    className,
    EmptySheetProps,
}) => {
    const { t } = useTranslation()

    const [openSheetId, setOpenSheetId] = React.useState<number | null>(null)

    const paginate = usePagination(getActivitiesQuery(type), {
        company_id: companyId,
        is_expired: isExpired,
    })

    const hasMore = React.useMemo(() => {
        return paginate.meta?.paginate.has_more
    }, [paginate.meta?.paginate.has_more])

    const translationKey = React.useMemo(() => {
        return type === 'my_sent' || type === 'my_sent_history'
            ? 'GiftCardMySent'
            : 'GiftCardSentToMe'
    }, [type])

    if (!paginate.data || !paginate.meta) return null

    return (
        <React.Fragment>
            <InfiniteScroll
                dataLength={paginate.data.length}
                hasMore={hasMore}
                next={paginate.more}
                loader={<GiftCardActivitiesLoader />}
                className={cn(
                    '!overflow-hidden duration-300 animate-in fade-in zoom-in-95',
                    className,
                )}>
                {map(paginate.data, item => {
                    if (
                        isLoader &&
                        paginate.query.isFetching &&
                        paginate.query.originalArgs?.page === 1
                    )
                        return null

                    const company = GiftCardUtils.findCompanyFromMeta(
                        item.company_id,
                        paginate.meta,
                    )

                    if (!company) return null

                    return (
                        <Sheet
                            key={item.id}
                            open={openSheetId === item.id}
                            onOpenChange={isOpen =>
                                setOpenSheetId(isOpen ? item.id : null)
                            }>
                            <Sheet.Trigger className="w-full" asChild>
                                <div onClick={() => setOpenSheetId(item.id)}>
                                    <PersonalItem
                                        type="history"
                                        title={company.name}
                                        description={getPersonalItemDescription(
                                            type,
                                            item,
                                        )}
                                        avatarChildComponent={
                                            <Avatar.Image
                                                src={company.logo}
                                                alt={company.name}
                                                className="rounded-full border-[1.6px] border-semantic-background-tertiary object-contain"
                                            />
                                        }
                                        divider
                                        amount={item.balance}
                                        currency={company.currency}
                                        datetime={dayjs(
                                            item.expire_date,
                                        ).toDate()}
                                    />
                                </div>
                            </Sheet.Trigger>
                            <Sheet.Content className="overflow-y-scroll">
                                <GiftCardDetail
                                    type={type}
                                    data={item}
                                    company={company}
                                    onOpenChange={setOpenSheetId}
                                />
                            </Sheet.Content>
                        </Sheet>
                    )
                })}

                {isLoader && paginate.query.isFetching && (
                    <GiftCardActivitiesLoader />
                )}
                {paginate.data.length === 0 && !paginate.query.isFetching && (
                    <EmptySheet
                        icon="info"
                        title={t(`@wallet.${translationKey}.empty_sheet_title`)}
                        description={t(
                            `@wallet.${translationKey}.empty_sheet_description`,
                        )}
                        className={cn(
                            'max-w-full pb-8 pt-16',
                            EmptySheetProps?.className,
                        )}
                        {...EmptySheetProps}
                    />
                )}
            </InfiniteScroll>
        </React.Fragment>
    )
}
export default GiftCardActivities
