import { createApi } from '@reduxjs/toolkit/query/react'
import type {
    AppInfoResponse,
    InitializeData,
    InitializeResponse,
    UserMeResponse,
} from './BaseApi.types'
import BaseQuery from './BaseQuery'

const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: BaseQuery,
    tagTypes: [
        'Me',
        'WalletDetail',
        'BalanceHistoryList',
        'BalanceHistoryDetail',
        'DepositCampaignDetail',
        'CardList',
        'GiftCardMySent',
    ],
    endpoints: builder => ({
        appInfo: builder.query<AppInfoResponse, void>({
            query: () => ({
                url: 'waas/app-info',
                method: 'GET',
            }),
        }),
        initialize: builder.query<InitializeResponse, InitializeData>({
            query: data => {
                localStorage.clear()
                return {
                    url: `waas/token/validate/${data.token}`,
                    method: 'GET',
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                await queryFulfilled
                dispatch(baseApi.endpoints.appInfo.initiate())
            },
        }),
        me: builder.query<UserMeResponse, void>({
            query: () => {
                return {
                    url: 'v2/user/me',
                    method: 'GET',
                }
            },
            providesTags: ['Me'],
        }),
    }),
})

export default baseApi
