import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, Sheet } from '@components'
import walletApi from '@wallet/api'
import type { GiftCardHistoryDetailProps } from './GiftCardDetail.types.ts'
import { useNotification } from '@core/hooks'
import GiftCardDetailSection from './GiftCardDetailSection.tsx'

const GiftCardDetail: React.FC<GiftCardHistoryDetailProps> = ({
    type,
    data,
    company,
    onOpenChange,
}) => {
    const { t } = useTranslation()
    const { toast, alert } = useNotification()
    const navigate = useNavigate()

    const [giftCardCancel, giftCardCancelResult] =
        walletApi.useGiftCardCancelMutation()

    const isCancelType = React.useMemo(() => type === 'my_sent', [type])

    const cancelGiftCard = React.useCallback(async () => {
        await giftCardCancel({ gift_card_id: data.id }).unwrap()

        toast({
            variant: 'success',
            icon: 'check-circle',
            message: t('@wallet.GiftCardDetail.gift_card_cancel_toast_message'),
            duration: 5000,
        })

        navigate('/', { replace: true })
    }, [data.id, giftCardCancel, navigate, t, toast])

    const showGiftCardCancelAlert = React.useCallback(() => {
        onOpenChange(null)

        return alert({
            title: t('@wallet.GiftCardDetail.gift_card_cancel_alert_title'),
            message: t('@wallet.GiftCardDetail.gift_card_cancel_alert_message'),
            icon: 'warning',
            actions: [
                {
                    children: t(
                        '@wallet.GiftCardDetail.gift_card_cancel_alert_approval_button',
                    ),
                    loading: true,
                    onClick: () => cancelGiftCard(),
                },
                {
                    variant: 'secondary',
                    children: t(
                        '@wallet.GiftCardDetail.gift_card_cancel_alert_reject_button',
                    ),
                },
            ],
        })
    }, [alert, cancelGiftCard, onOpenChange, t])

    return (
        <React.Fragment>
            <Sheet.Header>
                <Sheet.Title>
                    {t('@wallet.GiftCardHistoryDetail.section_bar_title')}
                </Sheet.Title>
                <Sheet.Description>
                    {t('@wallet.GiftCardHistoryDetail.section_bar_description')}
                </Sheet.Description>
            </Sheet.Header>
            <div className="space-y-9">
                {isCancelType && (
                    <Button
                        variant="ink-tonal"
                        className="w-full"
                        loading={giftCardCancelResult.isLoading}
                        onClick={showGiftCardCancelAlert}>
                        {t('@wallet.GiftCardDetail.cancel_gift_card')}
                    </Button>
                )}

                <GiftCardDetailSection
                    type={type}
                    data={data}
                    company={company}
                />
            </div>
        </React.Fragment>
    )
}
export default GiftCardDetail
