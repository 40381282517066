import * as React from 'react'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'
import type { PersonalItemContentProps } from './PersonalItem.types'

const PersonItemDetail: React.FC<PersonalItemContentProps> = ({
    amount,
    currency,
    date,
}) => {
    if (!amount || !currency || !date) return null

    return (
        <div className="flex flex-col items-end gap-[2px]">
            <span className="text-body-md-b text-semantic-content-ink">
                {CurrencyFormatter.format(amount, i18n.getLocale(), currency)}
            </span>
            <span className="text-body-sm-r text-semantic-content-inkLight">
                {dayjs(date).format('DD MMM • hh:mm')}
            </span>
        </div>
    )
}
export default PersonItemDetail
