import * as React from 'react'
import { Link, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { filter, map, some } from 'lodash'
import { Icon } from '@components'
import { useAppSelector } from '@core/store'
import type { SidebarItemProps } from './Sidebar.types.ts'
import { SendPostMessage } from '@core/utils'
import { SidebarMenuItem } from './Sidebar.constants'

const SidebarItem: React.FC<SidebarItemProps> = ({ isMobile }) => {
    const { t } = useTranslation()
    const location = useLocation().pathname

    const walletMeta = useAppSelector(state => state.baseSlice.wallet_modules)

    const modules = React.useMemo(() => {
        return filter(walletMeta, item => item.status)
    }, [walletMeta])

    return (
        <React.Fragment>
            {map(SidebarMenuItem, (item, index) => {
                const hasPermission = some(modules, {
                    name: item.permission,
                })

                if (item.permission && !hasPermission) return null

                return (
                    <Link
                        key={index}
                        to={item.pathname}
                        className="sidebar-menu-detail__item">
                        <div
                            data-state={
                                item.pathname === location
                                    ? 'active'
                                    : 'inactive'
                            }
                            className="sidebar-menu-detail__item-icon">
                            <Icon name={item.icon} size={24} />
                        </div>
                        <div className="sidebar-menu-detail__item-content">
                            <h3 className="sidebar-menu-detail__item-content-title">
                                {t(item.title)}
                            </h3>
                            <div className="sidebar-menu-detail__item-content-icon">
                                <Icon name="caret-right" size={24} />
                            </div>
                        </div>
                    </Link>
                )
            })}
            {!isMobile && (
                <button
                    className="sidebar-menu-detail__item lg:!hidden"
                    onClick={() =>
                        SendPostMessage(
                            JSON.stringify({
                                event: 'ReturnToSite',
                            }),
                        )
                    }>
                    <div
                        data-state="inactive"
                        className="sidebar-menu-detail__item-icon">
                        <Icon name="wallet" size={24} />
                    </div>
                    <div className="sidebar-menu-detail__item-content">
                        <h3 className="sidebar-menu-detail__item-content-title">
                            {t('content_config.Sidebar.return_to_site')}
                        </h3>
                        <div className="sidebar-menu-detail__item-content-icon">
                            <Icon name="caret-right" size={24} />
                        </div>
                    </div>
                </button>
            )}
        </React.Fragment>
    )
}

export default SidebarItem
