import * as React from 'react'
import {
    Navigate,
    Outlet,
    ScrollRestoration,
    useLocation,
    useNavigate,
} from 'react-router'
import { isEqual } from 'lodash'
import { Footer, Sidebar } from '@components'
import { TopUpProvider } from '@core/context'
import { useAppSelector } from '@core/store'
import { NotificationProvider, useSocketListener } from '@core/hooks'
import { isFrozenRoutes } from './Auth.utils.ts'
import isHiddenLayoutRoutes from './Auth.constants.ts'
import AuthMobileHeader from './AuthMobileHeader.tsx'
import '../Layout.css'

const Auth: React.FC = () => {
    const location = useLocation()
    const navigate = useNavigate()
    useSocketListener()

    const state = useAppSelector(state => state.baseSlice)

    const token = localStorage.getItem('user.access_token')

    const isHidden = React.useMemo(() => {
        return isHiddenLayoutRoutes.includes(location.pathname)
    }, [location.pathname])

    React.useEffect(() => {
        const isFrozen = isFrozenRoutes(location.pathname)

        if (state.company.is_wallet_frozen && isFrozen)
            navigate('/', { replace: true })
    }, [location.pathname, navigate, state.company])

    if (!token) return <Navigate to="/404" replace />

    return (
        <React.Fragment>
            <NotificationProvider>
                <TopUpProvider>
                    <div className="flex flex-col lg:flex-row">
                        <Sidebar
                            fullName={state.user.first_name}
                            logo={state.company.logo}
                            avatarUrl={state.user.details.avatar_url}
                            color={state.company.color}
                            isMobile={isEqual(state.platform, 'mobile_waas')}
                            isHidden={isHidden}
                            hideMenu={state.settings.hide_menu}
                        />
                        <AuthMobileHeader
                            fullName={state.user.first_name}
                            logo={state.company.logo}
                            avatarUrl={state.user.details.avatar_url}
                            isHidden={isHidden}
                            hideHeader={state.settings.hide_header}
                        />
                        <main className="main-wrapper">
                            <div className="main-content">
                                <Outlet />
                            </div>
                            <Footer isHidden={isHidden} />
                        </main>
                    </div>
                </TopUpProvider>
            </NotificationProvider>
            <ScrollRestoration />
        </React.Fragment>
    )
}

export default Auth
