import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Formik, type FormikProps, type FormikValues } from 'formik'
import { filter } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import {
    Badge,
    Button,
    DateTimeField,
    IconButton,
    Input,
    InputCurrency,
    PersonalItem,
    PersonField,
    Sheet,
} from '@components'
import i18n from '@core/translations'
import walletApi, { type FindUserItem } from '@wallet/api'
import type { GiftCardMakeForm, GiftCardMakeProps } from './GiftCard.types'
import { useNotification } from '@core/hooks'
import { getInputCurrencyDetails } from './GiftCard.utils'
import { giftCardMakeValidationSchema } from './GiftCard.validations'
import GiftCardAddUser from './GiftCardAddUser'

const GiftCardMake: React.FC<GiftCardMakeProps> = ({ wallet }) => {
    const { t } = useTranslation()
    const { toast, alert } = useNotification()
    const navigate = useNavigate()

    const formikRef = React.useRef<FormikProps<GiftCardMakeForm>>(null)

    const [isOpen, setIsOpen] = React.useState<boolean>(false)
    const [persons, setPersons] = React.useState<FindUserItem[]>([])

    const [giftCardMake, giftCardResult] = walletApi.useGiftCardMakeMutation()

    const makeGiftCard = React.useCallback(
        async (values: FormikValues) => {
            await giftCardMake({
                wallet_id: values.wallet_id,
                balance: values.balance,
                expire_date: dayjs(values.expire_date).format(),
                description: values.description,
                phones: values.phones,
            }).unwrap()

            toast({
                variant: 'success',
                icon: 'check-circle',
                message: t('@wallet.GiftCardMake.toast_success_message'),
                duration: 5000,
            })

            navigate('/', { replace: true })
        },
        [giftCardMake, navigate, t, toast],
    )

    const showBalanceWarning = React.useCallback(() => {
        if (!wallet) return

        alert({
            icon: 'warning',
            title: t('@wallet.GiftCardMake.alert_title'),
            message: t('@wallet.GiftCardMake.balance_alert_message'),
            actions: [
                {
                    variant: 'primary',
                    children: t(
                        '@wallet.GiftCardMake.balance_alert_top_up_button',
                    ),
                    onClick: () => wallet && navigate('/topup'),
                },
                {
                    variant: 'secondary',
                    children: t(
                        '@wallet.GiftCardMake.balance_alert_back_button',
                    ),
                },
            ],
        })
    }, [alert, navigate, t, wallet])

    const showSubmitWarning = React.useCallback(
        async (values: FormikValues) => {
            const hiddenSubmitAlert = localStorage.getItem(
                'settings.gift_card_submit_alert_hidden',
            )

            if (hiddenSubmitAlert) return makeGiftCard(values)

            alert({
                icon: 'gift',
                title: t('@wallet.GiftCardMake.submit_alert_title'),
                message: t('@wallet.GiftCardMake.submit_alert_message'),
                actions: [
                    {
                        children: t(
                            '@wallet.GiftCardMake.submit_alert_approve_hidden_button',
                        ),
                        onClick: () => {
                            localStorage.setItem(
                                'settings.gift_card_submit_alert_hidden',
                                'true',
                            )

                            makeGiftCard(values)
                        },
                    },
                    {
                        variant: 'secondary',
                        children: t(
                            '@wallet.GiftCardMake.submit_alert_approve_button',
                        ),
                        onClick: () => {
                            makeGiftCard(values)
                        },
                    },
                    {
                        variant: 'secondary',
                        children: t(
                            '@wallet.GiftCardMake.submit_alert_reject_button',
                        ),
                    },
                ],
            })
        },
        [alert, makeGiftCard, t],
    )

    const handleSubmit = React.useCallback(
        async (values: GiftCardMakeForm) => {
            if (!values.wallet_id || !values.expire_date || !wallet) return

            if (wallet.balance < values.balance * values.phones.length)
                return showBalanceWarning()

            showSubmitWarning(values)
        },
        [showBalanceWarning, showSubmitWarning, wallet],
    )

    return (
        <React.Fragment>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    wallet_id: wallet.id,
                    balance: 0,
                    expire_date: undefined,
                    description: '',
                    phones: [],
                }}
                validationSchema={giftCardMakeValidationSchema}
                onSubmit={handleSubmit}>
                {formik => (
                    <div className="space-y-8">
                        <InputCurrency
                            value={
                                formik.values.balance === 0
                                    ? ''
                                    : formik.values.balance
                            }
                            label={t(
                                '@wallet.GiftCardMake.form_balance_section_title',
                            )}
                            placeholder={`${
                                getInputCurrencyDetails(
                                    wallet?.company.currency,
                                ).symbol
                            }0`}
                            prefix={
                                getInputCurrencyDetails(
                                    wallet?.company.currency,
                                ).symbol
                            }
                            decimalSeparator={
                                getInputCurrencyDetails(
                                    wallet?.company.currency,
                                ).seperator
                            }
                            groupSeparator={
                                getInputCurrencyDetails(
                                    wallet?.company.currency,
                                ).decimal
                            }
                            decimalScale={0}
                            maxLength={100000}
                            allowNegativeValue={false}
                            onBlur={formik.handleBlur('balance')}
                            onValueChange={value => {
                                if (value === undefined)
                                    return formik.setFieldValue('balance', 0)

                                formik.setFieldValue('balance', value ?? 0)
                            }}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            step={1}
                            error={
                                formik.touched.balance &&
                                !!formik.errors.balance
                            }
                            helperText={
                                formik.touched.balance
                                    ? formik.errors.balance
                                    : undefined
                            }
                        />
                        <DateTimeField
                            date={formik.values.expire_date}
                            setDate={date =>
                                formik.setFieldValue('expire_date', date)
                            }
                            InputProps={{
                                label: t(
                                    '@wallet.GiftCardMake.form_date_picker_section_title',
                                ),
                                placeholder: t(
                                    '@wallet.GiftCardMake.form_date_picker_placeholder',
                                ),
                                error:
                                    formik.touched.expire_date &&
                                    !!formik.errors.expire_date,
                                helperText: formik.touched.expire_date
                                    ? formik.errors.expire_date
                                    : undefined,
                            }}
                        />

                        <Input
                            type="text"
                            label={t(
                                '@wallet.GiftCardMake.form_description_section_title',
                            )}
                            placeholder={t(
                                '@wallet.GiftCardMake.form_description_placeholder',
                            )}
                            value={formik.values.description}
                            onBlur={formik.handleBlur('description')}
                            onChange={formik.handleChange('description')}
                            error={
                                formik.touched.description &&
                                !!formik.errors.description
                            }
                            helperText={
                                formik.touched.description
                                    ? formik.errors.description
                                    : undefined
                            }
                        />

                        <PersonField
                            data={persons}
                            onChange={value =>
                                formik.setFieldValue('phones', value)
                            }
                            PersonItemProps={item => ({
                                LayoutRightComponent: (
                                    <div className="flex items-center gap-2">
                                        {formik.values.balance > 0 && (
                                            <Badge
                                                variant="yellow"
                                                children={CurrencyFormatter.format(
                                                    +formik.values.balance,
                                                    i18n.getLocale(),
                                                    wallet.company.currency,
                                                )}
                                                size="tiny"
                                            />
                                        )}

                                        <IconButton
                                            name="close"
                                            size="tiny"
                                            variant="ink-tonal"
                                            onClick={() =>
                                                setPersons(
                                                    filter(
                                                        persons,
                                                        person =>
                                                            person.phone !==
                                                            item.phone,
                                                    ),
                                                )
                                            }
                                        />
                                    </div>
                                ),
                            })}
                            ListFooterComponent={
                                <PersonalItem
                                    title={t(
                                        '@wallet.GiftCardMake.form_person_field_footer',
                                    )}
                                    onClick={() => setIsOpen(true)}
                                />
                            }
                            error={
                                formik.touched.phones && !!formik.errors.phones
                            }
                            helperText={
                                formik.touched.phones && formik.errors.phones
                                    ? typeof formik.errors.phones === 'string'
                                        ? formik.errors.phones
                                        : formik.errors.phones[0]
                                    : undefined
                            }
                        />
                        <Button
                            disabled={!formik.isValid}
                            loading={giftCardResult.isLoading}
                            onClick={() => formik.handleSubmit()}
                            children={t(
                                '@wallet.GiftCardMake.form_submit_button',
                            )}
                            className="w-full"
                        />
                    </div>
                )}
            </Formik>

            <Sheet open={isOpen} onOpenChange={open => setIsOpen(open)}>
                <GiftCardAddUser
                    persons={persons}
                    setIsOpenChange={setIsOpen}
                    setPersons={setPersons}
                />
            </Sheet>
        </React.Fragment>
    )
}
export default GiftCardMake
