import * as React from 'react'
import type { ContainerContentProps } from './Container.types'
import { cn } from '@core/utils'

const ContainerContent: React.FC<ContainerContentProps> = ({
    children,
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'container flex py-8 lg:justify-center lg:py-[72px]',
                className,
            )}
            {...props}>
            <div className="w-full xl:w-[486px]">{children}</div>
        </div>
    )
}

export default ContainerContent
