import CampaignTranslations from '@campaign/translations'
import CommonTranslations from '@common/translations'
import GuestTranslations from '@guest/translations'
import DepositTranslations from '@deposit/translations'
import PaymentTranslations from '@payment/translations'
import WalletTranslations from '@wallet/translations'

export default {
    '@campaign': CampaignTranslations.tr,
    '@guest': GuestTranslations.tr,
    '@deposit': DepositTranslations.tr,
    '@payment': PaymentTranslations.tr,
    '@wallet': WalletTranslations.tr,
    '@common': CommonTranslations.tr,
    'socket_context': {
        refund_request_event: {
            alert_title: 'İade Talebi',
            alert_message:
                'Talebin üzerine {{amount}} tutarındaki iadeyi onaylıyor musun?',
            alert_action_submit: 'Kabul Et',
            alert_action_reject: 'Reddet',
            alert_action_submit_toast: 'İade işlemi başarıyla gerçekleşti',
            alert_action_reject_toast: 'İade işlemi iptal edildi',
        },
        payment_event: {
            toast_error_message: 'Ödeme İşlemi Başarısız',
            toast_no_balance_message:
                'Ödeme yapabilmek için bakiyen yetersizdir',
            toast_expire_message: 'Ödemenin geçerlilik süresi dolmuştur',
            toast_device_offline_message: 'Bağlantı kurulamadı',
            toast_callback_no: 'Cihaz yanıt vermiyor',
            toast_discount_point_change:
                'İndirimde yapılan değişiklik nedeniyle işlemin iptal edildi',
        },
    },
    'context': {
        TopUpProvider: {
            alert_success_title: 'Bakiye yükleme başarıyla gerçekleşti',
            toast_success_message:
                'İşlem detaylarını görüntülemek için cüzdan hareketlerini kontrol edebilirsin',
            alert_success_action: 'Kapat',
        },
    },
    'content_config': {
        Alert: {
            close_timer: '{{time}} sn. sonra kapanacak.',
        },
        Sidebar: {
            hello: 'Merhaba',
            my_transactions: 'İŞLEMLERİM',
            return_to_site: 'Siteye Dön',
            wallet: 'Cüzdan',
            account_activity: 'Hesap Hareketleri',
            campaigns: 'Kampanyalar',
            gift_card: 'Hediye Kartı',
        },
        Footer: {
            about_us: 'Macellan Cüzdan Nedir?',
            customer_service: 'Müşteri Hizmetleri & İletişim',
            faq: 'Sıkça Sorulan Sorular',
            copyright: `Macellan SuperApp © ${new Date().getFullYear()}. Her hakkı saklıdır.`,
        },
        CampaignItem: {
            details_button_text: 'Detay',
            disabled_button_text: 'Faydalandın',
            remaining_time: 'Kalan Süre',
            day: 'Gün',
            point_suffix: 'puan hediye',
        },
        HistoryItem: {
            refund: 'İptal / İade',
            topUp: 'Bakiye Yükleme',
            capture: 'Bakiye Harcama',
            refund_approval: 'İade Onay Bekliyor',
            refund_reject: 'İade Reddedildi',
        },
        RewardChart: {
            table_type_title: 'YÜKLE KAZAN',
            progress_type_title: 'HARCA KAZAN',
            progress_type_description: 'harcama\n kaldı',
            table_type_description:
                'Bakiye yüklemelerinden kazandığın toplam puan',
        },
        WalletCard: {
            placeholders_point: 'Puan',
        },
    },
    'hooks': {
        useTopUp: {
            message: 'Ödeme yöntemi ekleyiniz.',
        },
    },
    'validation': {
        error_message: {
            /* Mixed Validation Messages */
            mixed_required: 'Bu alan zorunludur.',
            mixed_default: 'Girilen değer doğru tipte değil.',
            mixed_one_of: 'Geçersiz bir değer girdiniz.',
            /* String Validation Messages */
            string_length: 'Bu alan {{length}} karakterden oluşmalıdır.',
            string_min: 'Bu alan en az {{min}} karakterden oluşmalıdır.',
            string_max: 'Bu alan en fazla {{max}} karakterden oluşmalıdır.',
            string_email: 'Girilen değer e-posta formatında olmalıdır.',
            string_matches: 'Uygun formatta değer giriniz.',
            string_url: 'Girilen değer url formatında olmalıdır.',
            /* Number Validation Messages */
            number_min: 'Girilen sayı {{min}} değerinden küçük olamaz.',
            number_max: 'Girilen sayı {{max}} değerinden büyük olamaz.',
            number_less_than:
                'Girilen sayı {{less}} değerinden büyük olmalıdır.',
            number_more_than:
                'Girilen sayı {{more}} değerinden küçük olmalıdır.',
            number_positive: 'Girilen değer sıfırdan büyük olmalıdır.',
            number_negative: 'Girilen değer sıfırdan küçük olmalıdır.',
            number_integer: 'Girilen değer tam sayı olmalıdır.',
            /* Date Validation Messages */
            date_min: 'Girilen tarih {{min}} tarihinden küçük olamaz.',
            date_max: 'Girilen tarih {{max}} tarihinden büyük olamaz.',
            /* Array Validation Messages */
            array_length:
                'Belirtilen eleman sayısı {{length}} kadar olmalıdır.',
            array_min:
                'Belirtilen eleman sayısı en az {{min}} kadar olmalıdır.',
            array_max:
                'Belirtilen eleman sayısı en fazla {{max}}} kadar olmalıdır.',
        },
    },
}
