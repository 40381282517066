import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import { DetailSection } from '@components'
import i18n from '@core/translations'
import type { GiftCardHistoryDetailSectionProps } from './GiftCardDetail.types.ts'

const GiftCardDetailSection: React.FC<GiftCardHistoryDetailSectionProps> = ({
    type,
    data,
    company,
    ...props
}) => {
    const { t } = useTranslation()

    const statusLabel = React.useMemo(() => {
        const statusMap: Record<string, string> = {
            expired: t(
                '@wallet.GiftCardHistoryDetail.detail_section_status_expired',
            ),
            cancel: t(
                '@wallet.GiftCardHistoryDetail.detail_section_status_cancel',
            ),
            finished: t(
                '@wallet.GiftCardHistoryDetail.detail_section_status_finished',
            ),
        }
        return statusMap[data?.status] || ''
    }, [data.status, t])

    return (
        <DetailSection
            title={t('@wallet.GiftCardHistoryDetail.detail_section_title')}
            data={[
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_name_surname',
                    ),
                    description:
                        type === 'my_sent' || type === 'my_sent_history'
                            ? (data.receiver?.full_name ??
                              t(
                                  '@wallet.GiftCardHistoryDetail.detail_section_anonymous',
                              ))
                            : data.sender.full_name,
                },
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_phone_number',
                    ),
                    description:
                        type === 'my_sent' || type === 'my_sent_history'
                            ? data.receiver_phone
                            : data.sender.phone,
                },
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_deposit',
                    ),
                    description:
                        data.balance === 0
                            ? t(
                                  '@wallet.GiftCardHistoryDetail.detail_section_limitless',
                              )
                            : CurrencyFormatter.format(
                                  data.balance,
                                  i18n.getLocale(),
                                  company.currency,
                              ),
                },
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_description',
                    ),
                    description: data.description,
                },
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_status',
                    ),
                    description: statusLabel,
                },
                {
                    title: t(
                        '@wallet.GiftCardHistoryDetail.detail_section_expire_date',
                    ),
                    description: dayjs(data.expire_date).format('DD/MM/YYYY'),
                },
            ]}
            {...props}
        />
    )
}
export default GiftCardDetailSection
