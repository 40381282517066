import * as React from 'react'
import { Skeleton } from '@components'
import { cn } from '@core/utils'

const PersonalItemLoader: React.FC<React.ComponentProps<'div'>> = ({
    className,
    ...props
}) => {
    return (
        <div
            className={cn(
                'space-y-4 py-3 duration-300 animate-in fade-in',
                className,
            )}
            {...props}>
            <div className="flex items-center space-x-4 px-4">
                <div>
                    <Skeleton className="h-12 w-12 shrink-0 rounded-full bg-semantic-background-secondary" />
                </div>

                <div className="flex w-full items-center justify-between gap-4">
                    <div className="space-y-[2px]">
                        <Skeleton className="col-span-2 h-2 w-full rounded bg-semantic-background-secondary md:w-[120px]" />
                        <Skeleton className="col-span-2 h-2 w-full rounded bg-semantic-background-secondary md:w-[85px]" />
                    </div>
                    <div className="flex shrink-0 flex-col items-center justify-center gap-2">
                        <Skeleton className="col-span-1 ml-auto h-2 w-16 rounded bg-semantic-background-secondary" />
                        <Skeleton className="col-span-1 ml-auto h-2 w-16 rounded bg-semantic-background-secondary" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PersonalItemLoader
