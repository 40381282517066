import { CurrencyFormatter } from '@macellan/formatter'
import i18n from '@core/translations'

export const getInputCurrencyDetails = (currency: string | undefined) => {
    if (!currency) return { symbol: '', seperator: '.', decimal: ',' }
    const symbol = CurrencyFormatter.getSymbol(currency)
    const seperators = CurrencyFormatter.getLocaleSeparators(i18n.getLocale())

    return {
        symbol: symbol,
        decimal: seperators.decimal,
        seperator: seperators.separator,
    }
}
