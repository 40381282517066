import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedControl, Sheet } from '@components'
import { GiftCardActivities } from '@wallet/views'
import type { GiftCardHistoryProps } from './GiftCardHistory.types'

const GiftCardHistory: React.FC<GiftCardHistoryProps> = ({
    type,
    companyId,
}) => {
    const { t } = useTranslation()

    return (
        <Sheet.Content className="overflow-y-scroll">
            <Sheet.Header>
                <Sheet.Title>
                    {t('@wallet.GiftCardHistoryTabs.route_header_title')}
                </Sheet.Title>
            </Sheet.Header>

            <SegmentedControl defaultValue={type}>
                <SegmentedControl.List className="mb-10">
                    <SegmentedControl.Trigger value="my_sent">
                        {t('@wallet.GiftCardMySent.route_header_title')}
                    </SegmentedControl.Trigger>
                    <SegmentedControl.Trigger value="sent_to_me">
                        {t(
                            '@wallet.GiftCardSentToMeHistory.route_header_title',
                        )}
                    </SegmentedControl.Trigger>
                </SegmentedControl.List>

                <SegmentedControl.Content value="my_sent">
                    <GiftCardActivities
                        type="my_sent_history"
                        companyId={companyId}
                        isExpired="1"
                        isLoader={false}
                        className="!animate-none"
                        EmptySheetProps={{
                            className: '!animate-none',
                        }}
                    />
                </SegmentedControl.Content>

                <SegmentedControl.Content
                    value="sent_to_me"
                    className="!animate-none">
                    <GiftCardActivities
                        type="sent_to_me_history"
                        companyId={companyId}
                        isExpired="1"
                        isLoader={false}
                        className="!animate-none"
                        EmptySheetProps={{
                            className: '!animate-none',
                        }}
                    />
                </SegmentedControl.Content>
            </SegmentedControl>
        </Sheet.Content>
    )
}
export default GiftCardHistory
