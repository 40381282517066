import * as React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CurrencyFormatter } from '@macellan/formatter'
import i18next from '@core/translations'
import type { CampaignDetailHeaderProps } from './CampaignDetail.types'

const CampaignDetailHeader: React.FC<CampaignDetailHeaderProps> = ({
    type,
    source,
    percent,
    point,
    endDate,
    currency,
}) => {
    const { t } = useTranslation()

    const formattedPoint = React.useMemo(() => {
        return CurrencyFormatter.format(point, i18next.getLocale(), currency)
    }, [currency, point])

    const formattedDate = React.useMemo(() => {
        return dayjs(endDate).format('DD MMMM')
    }, [endDate])

    const formattedPercent = React.useMemo(() => {
        return percent ? `${percent}%` : ''
    }, [percent])

    return (
        <React.Fragment>
            <div
                className="campaign-detail__header"
                style={{ backgroundImage: `url(${source})` }}
            />
            <div className="campaign-detail__header-content">
                <div className="space-y-1">
                    <h4>{formattedDate}</h4>
                    <p>
                        {t(
                            '@campaign.CampaignDetail.content_header_left_title',
                        )}
                    </p>
                </div>
                <div className="space-y-1 pl-6">
                    <h4>
                        {type === 'percentage'
                            ? formattedPercent
                            : formattedPoint}
                    </h4>
                    <p>
                        {t(
                            '@campaign.CampaignDetail.content_header_right_title',
                        )}
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CampaignDetailHeader
