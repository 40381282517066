import * as React from 'react'
import { IconButton } from '@components'
import type { ContainerRightProps } from './Container.types.ts'

const ContainerRight: React.FC<ContainerRightProps> = ({
    title,
    description,
}) => {
    return (
        <div className="hidden shrink-0 border-l-[2px] border-l-semantic-background-secondary py-10 pl-[38px] pr-10 lg:block">
            <div className="w-[158px] space-y-4">
                <IconButton
                    as="span"
                    variant="ink-tonal"
                    size="medium"
                    name="info"
                    className="after:hidden"
                />
                <div className="space-y-2">
                    <h4 className="text-label-md text-semantic-content-ink">
                        {title}
                    </h4>
                    <p className="text-body-sm-r text-semantic-content-inkMedium">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ContainerRight
