import * as React from 'react'
import type { ContainerLeftProps } from './Container.types.ts'
import ContainerPaying from './ContainerPaying'

const ContainerLeft: React.FC<ContainerLeftProps> = ({
    name,
    currency,
    amount,
    point,
    children,
}) => {
    return (
        <div className="flex-grow">
            <ContainerPaying
                name={name}
                currency={currency}
                amount={amount}
                point={point}
            />
            {children}
        </div>
    )
}

export default ContainerLeft
