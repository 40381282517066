import * as React from 'react'
import type {
    ContainerProps,
    ContainerStaticProperties,
} from './Container.types.ts'
import ContainerContent from './ContainerContent'
import ContainerHeader from './ContainerHeader'
import ContainerLeft from './ContainerLeft'
import ContainerRight from './ContainerRight'

const Container: React.FC<ContainerProps> & ContainerStaticProperties = ({
    title,
    goBackProps,
    children,
}) => {
    return (
        <div className="min-h-inherit lg:bg-semantic-background-primary">
            <ContainerHeader title={title} buttonProps={goBackProps} />
            <div className="pb-10 lg:container lg:-mt-[109px]">
                <div className="flex bg-semantic-content-inkInverse lg:rounded-3xl">
                    {children}
                </div>
            </div>
        </div>
    )
}

Container.Content = ContainerContent
Container.Left = ContainerLeft
Container.Right = ContainerRight

export default Container
