import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

const SegmentedControlContent = React.forwardRef<
    React.ComponentRef<typeof TabsPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ ...rest }, forwardedRef) => {
    return <TabsPrimitive.Content ref={forwardedRef} {...rest} />
})
SegmentedControlContent.displayName = 'SegmentedControlContent'

export default SegmentedControlContent
